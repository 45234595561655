/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Link from '../../components/Link';
import Text from '../../components/Text';
import { TermsConditionsPropTypes } from '../../TermsConditions';
import styles from '../../styles.module.scss';

const TermsConditionsDefault = ({ name, description, linkName, linkUrl, children }) => {
  const [isActive, setActive] = useState(false);
  return (
    <div id="terms-conditions" className={styles.termsConditions}>
      <div className={styles.tabs}>
        {children || (
          <>
            <button
              onClick={() => setActive(prevState => !prevState)}
              data-testid="label"
              className={cn(styles.tabLabel, { [styles.active]: isActive })}
            >
              {name}
            </button>
            {linkName && <Link name={linkName} url={linkUrl} />}
            <Text isActive={isActive} content={description} />
          </>
        )}
      </div>
    </div>
  );
};

TermsConditionsDefault.defaultProps = {
  children: undefined,
};

TermsConditionsDefault.propTypes = { ...TermsConditionsPropTypes, children: PropTypes.node };

export default TermsConditionsDefault;
