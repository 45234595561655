import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Text = ({ content, isActive }) => {
  return (
    <div
      data-testid="content"
      className={cn({ [styles.contentActive]: isActive }, styles.tabContent)}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

Text.propTypes = {
  content: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default Text;
