import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Link = ({ name, url }) => {
  return (
    <div className={cn(styles.linkContainer)}>
      <a className={cn(styles.link)} data-testid="link" href={url}>
        {name}
      </a>
    </div>
  );
};

Link.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Link;
