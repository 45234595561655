/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import cn from 'classnames';
import Link from '../../components/Link';
import Text from '../../components/Text';
import { TermsConditionsPropTypes } from '../../TermsConditions';
import TermsConditionsDefault from '../TermsConditionsDefault';
import stylesDefault from '../../styles.module.scss';
import styles from './styles.module.scss';

const TermsConditionsH1Heading = ({ name, description, linkName, linkUrl }) => {
  const [isActive, setActive] = useState(false);
  return (
    <TermsConditionsDefault>
      <h1>
        <button
          onClick={() => setActive(prevState => !prevState)}
          data-testid="label"
          className={cn(stylesDefault.tabLabel, styles.tabLabel, { [stylesDefault.active]: isActive })}
        >
          {name}
        </button>
      </h1>
      <Link name={linkName} url={linkUrl} />
      <Text isActive={isActive} content={description} />
    </TermsConditionsDefault>
  );
};

TermsConditionsH1Heading.propTypes = { ...TermsConditionsPropTypes };

export default TermsConditionsH1Heading;
